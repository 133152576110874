import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
type Languages = 'en' | 'ar';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private currentLang: Languages = this.localStorageService.get('current~lang') || environment.defaultLanguage;
    private languageChangeSubject = new BehaviorSubject<Languages>(this.currentLang);
    constructor(
        @Inject(DOCUMENT) private document: Document,
        // dir: Directionality,
        private translateService: TranslateService,
        private localStorageService: LocalStorageService
    ) { }

    get lang(): Languages {
        return this.currentLang;
    }

    get languageChange$() {
        return this.languageChangeSubject.asObservable();
      }

      selectLang(lang: Languages ,dontRelaod: boolean = false) {
        if (lang !== this.currentLang) {
          this.currentLang = lang;
          this.setDocumentDirection(lang);
          this.translateService.use(lang);
          this.localStorageService.set('current~lang', lang);
          this.languageChangeSubject.next(lang);
        }
      }
    
      private setDocumentDirection(lang: Languages) {
        this.document.body.dir = lang === 'ar' ? 'rtl' : 'ltr';
        this.document.body.lang = lang;
      }
    getName(obj: any): string {
        let name: string = '';
        if (this.currentLang === 'en') {
            name = obj['nameEn']
        } else if (this.currentLang === 'ar') {
            name = obj['nameAr']
        }
        if (name)
            return name;
        else
            return obj['name'];
    }
}
